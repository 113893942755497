import React, { useMemo } from "react";

import { setShowMessageDetails, useAppDispatch, useAppSelector } from "app";
import { Icon } from "ui-kit";

import { Stack, Typography, useTheme } from "@mui/material";
import { getLocaleDateFormat } from "@sbm/fe-utils";
import { ConversationResponse } from "@types";

import { Wrapper } from "./styles";

interface Props {
  data: ConversationResponse;
  authorUserID: number;
  currentMessage: boolean;
}

export const ConversationCard = ({
  data,
  authorUserID,
  currentMessage,
}: Props) => {
  const theme = useTheme();
  const { message, user } = data;
  const { userProfile } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const isMessageOutgoing = useMemo(() => {
    const isOutgoingMessage = data.user.id === authorUserID;
    if (userProfile?.id === authorUserID) {
      return isOutgoingMessage;
    }
    return !isOutgoingMessage;
  }, [data.user.id, authorUserID, userProfile?.id]);

  return (
    <Stack
      display="flex"
      width="100%"
      alignItems="cemter"
      flexDirection={isMessageOutgoing ? "row" : "row-reverse"}
    >
      <Wrapper
        $isOutgoing={isMessageOutgoing}
        $currentMessage={currentMessage}
        onClick={() => {
          dispatch(setShowMessageDetails(data.message.id));
        }}
      >
        <Typography
          variant="subtitle2"
          color="primary.main"
          sx={{ whiteSpace: "pre-line" }}
        >
          {message.textOfTheMessage?.replace(/\s+$/g, "")}
        </Typography>
        <div className="message_description">
          <div>
            {message.hasAttachedFile ? (
              <Icon
                name="Paperclip"
                size={16}
                color={theme.palette.primary.main}
              />
            ) : (
              <div />
            )}
            {message.hasAttachedLink ? (
              <Icon name="Link" size={16} color={theme.palette.primary.main} />
            ) : (
              <div />
            )}
          </div>

          <Typography variant="body4">
            {message.inSendingProcess
              ? "Sending..."
              : getLocaleDateFormat(message.createdAt, "hh:mm", true)}
          </Typography>
        </div>
      </Wrapper>
    </Stack>
  );
};
