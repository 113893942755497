const API_URL = "https://api.sbm.systems";
const KEYCLOAK_URL = "https://keycloak.sbm.systems";
const REALM = "sbm-realm";
const CLIENT_ID = "sbm-frontend-client";
const WS_API_URL = "https://ws-api.sbm.systems/";

export const environment = {
  production: true,
  apiUrl: API_URL,
  realm: REALM,
  clientId: CLIENT_ID,
  WSApiUrl: WS_API_URL,
  authority: `${KEYCLOAK_URL}/realms/${REALM}/`,
  storeKeyPrefix: "sbm.",
  userStorageType: "local",
  stateStorageType: "local",
  muiLicenseKey:
    "f5648cb41c2d1c37c5610eadf8932afeTz05MzUxNSxFPTE3NTE0Nzg1MjEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
};
