export interface INotification {
  id: number;
  messageText: string;
  topic: string;
  messageDate: string | null;
  viewingDate: string | null;
  messageNumber: number;
  messageAuthor: {
    typeOfAuthor: "user" | "system";
    authorName?: string;
  };
  messageViewed: boolean;
  isImportant: boolean;
  additionalInformation: string | null;
}

export type TAutocompleteOption = {
  title: string;
  id: number;
};

export interface IFilterOption {
  title: string;
  value: string | string[];
  field?: string;
  options?: any[];
  optionType?: string;
  multiSelect?: boolean;
  type?: string;
  variant?: string;
}

export interface IMeta {
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
  unreadCount?: number;
  activeCount?: number;
  completedCount?: number;
  inTheSpotlightCount?: number;
  requiresActionCount?: number;
}

export interface IWorkingDay {
  id: number;
  date: string;
  workingDay: boolean;
  createdAt: string;
  updatedAt: string;
}

export enum DrawerTypes {
  previewOrganizationStructerDrawer = "previewOrganizationStructerDrawer",
  messageDrawer = "messageDrawer",
  messageView = "message_view",
  createOrgDrawer = "createOrgDrawer",
  createLetterDrawer = "createLetterDrawer",
  createOrganizationStructerDrawer = "createOrganizationStructerDrawer",
  viewStaffUnitDrawer = "viewStaffUnitDrawer",
  staffAllocationDetails = "staffAllocationDetails",
}
